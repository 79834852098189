import styled from "@emotion/styled";
import { Button, Label, Radio, Select } from "@theme-ui/components";
import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useScreens } from "../../app/context/screens";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import { useFirebase } from "../../app/context/Firebase";
import { transformDocSnap } from "../../app/context/helpers";
import { useAuthContext } from "../../app/context/auth";

export default function ScreenSettings() {
  const { updateSettings } = useScreens();
  const { user } = useAuthContext();
  const { addToast } = useToasts();
  const [view, setView] = useState("year");
  // const [warehouseValues, setWarehouseValues] = useState(null);
  const [compare1, setCompare1] = useState(0);
  const [compare2, setCompare2] = useState(0);
  const firebase = useFirebase();
  const monthOptions = [
    {
      key: 0,
      text: "January",
      value: 0,
    },
    {
      key: 1,
      text: "February",
      value: 1,
    },
    {
      key: 2,
      text: "March",
      value: 2,
    },
    {
      key: 3,
      text: "April",
      value: 3,
    },
    {
      key: 4,
      text: "May",
      value: 4,
    },
    {
      key: 5,
      text: "June",
      value: 5,
    },
    {
      key: 6,
      text: "July",
      value: 6,
    },
    {
      key: 7,
      text: "August",
      value: 7,
    },
    {
      key: 8,
      text: "September",
      value: 8,
    },
    {
      key: 9,
      text: "October",
      value: 9,
    },
    {
      key: 10,
      text: "November",
      value: 10,
    },
    {
      key: 11,
      text: "December",
      value: 11,
    },
  ];

  const handleChange1 = (e) => setCompare1(e.target.value);
  const handleChange2 = (e) => setCompare2(e.target.value);

  // useEffect(() => {
  //   const unsubscribe = user
  //     ? firebase
  //         .screen("warehouse")
  //         .onSnapshot((snap) => setWarehouseValues(transformDocSnap(snap)))
  //     : () => console.log("not logged in");
  //   return () => unsubscribe();
  // }, [firebase, user]);

  const update = (view, compare1, compare2) => {
    updateSettings(view, compare1, compare2)
      .then(() => {
        addToast("Settings updated successfully.", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
          transitionDuration: 10,
          transitionState: "entered",
        });
      })
      .catch((error) => {
        addToast(`Problem updating settings: ${error}`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 3000,
          transitionDuration: 10,
          transitionState: "entered",
        });
        console.log(error);
      });
  };

  // const getMonths = () => {
  //   let counter = 0;

  //   if (warehouseValues !== null) {
  //     for (const [key, value] of Object.entries(warehouseValues)) {
  //       if (key !== "id") {
  //         if (value.length > counter) {
  //           counter = value.length;
  //         }
  //       }
  //     }
  //     console.log(counter);
  //     setMonths(monthOptions.slice(0, counter));
  //   }
  //   console.log(months);
  // };

  return (
    <>
      <Label>View Mode:</Label>
      <Choices>
        <InputWrap>
          <Label variant="radioLabel" style={{ textTransform: "none" }}>
            <Radio
              name="filter"
              value="firstLogin"
              checked={view === "compare"}
              onChange={(e) => {
                setView("compare");
              }}
            />
            Compare
          </Label>
        </InputWrap>
        <InputWrap style={{ marginRight: "10px" }}>
          <Label variant="radioLabel" style={{ textTransform: "none" }}>
            <Radio
              name="filter"
              value=""
              checked={view === "3months"}
              onChange={(e) => {
                setView("3months");
              }}
            />
            3 Months
          </Label>
        </InputWrap>
        <InputWrap style={{ marginRight: "10px" }}>
          <Label variant="radioLabel" style={{ textTransform: "none" }}>
            <Radio
              name="filter"
              value="notApproved"
              checked={view === "6months"}
              onChange={(e) => {
                setView("6months");
              }}
            />
            6 Months
          </Label>
        </InputWrap>
        <InputWrap>
          <Label variant="radioLabel" style={{ textTransform: "none" }}>
            <Radio
              name="filter"
              value="firstLogin"
              checked={view === "year"}
              onChange={(e) => {
                setView("year");
              }}
            />
            Year to Date
          </Label>
        </InputWrap>
      </Choices>
      {view === "compare" ? (
        <>
          <InputWrap>
            <Select value={compare1} onChange={handleChange1}>
              {monthOptions &&
                monthOptions.map((o) => (
                  <option value={o.value} key={o.key}>
                    {o.text}
                  </option>
                ))}
            </Select>
          </InputWrap>
          <Label variant="radioLabel" style={{ textTransform: "none" }}>
            with
          </Label>
          <InputWrap>
            <Select value={compare2} onChange={handleChange2}>
              {monthOptions &&
                monthOptions.map((o) => (
                  <option value={o.value} key={o.key}>
                    {o.text}
                  </option>
                ))}
            </Select>
          </InputWrap>
        </>
      ) : null}
      <Button
        style={{ marginTop: "1em" }}
        onClick={() => {
          update(view, compare1, compare2);
        }}
      >
        Update
      </Button>
    </>
  );
}

const Choices = styled.div`
  display: flex;
  align-content: center;
  @media (max-width: 576px) {
    display: block;
  }
`;
