export const standardNavItems = [
  {
    title: "Sales",
    link: "/sales",
    icon: ["fal", "money-bill"],
  },
  {
    title: "Warehouse",
    link: "/warehouse",
    icon: ["fal", "warehouse"],
  },
  // ...(process.env.REACT_APP_TEAMMATES === "true"
  //   ? [
  //       {
  //         title: "Teammates",
  //         link: "/teammates",
  //         icon: ["fal", "user-friends"],
  //       },
  //     ]
  //   : []),
  // ...(process.env.REACT_APP_NEWSFLASH === "true"
  //   ? [
  //       {
  //         title: "Newsflash",
  //         link: "/newsflash",
  //         icon: ["fal", "newspaper"],
  //       },
  //     ]
  //   : []),
  // ...(process.env.REACT_APP_VIEW_POINTS === "true"
  //   ? [
  //       {
  //         title: "Points Log",
  //         link: "/points-log",
  //         icon: ["fal", "star"],
  //       },
  //     ]
  //   : []),
  /* {
    title: "Manage Data",
    link: "/manage-data",
    icon: ["fal", "hospital-user"],
  }, */
];

export const notApprovedNavItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: ["fal", "th"],
  },
  ...(process.env.REACT_APP_NEWSFLASH === "true"
    ? [
        {
          title: "Newsflash",
          link: "/newsflash",
          icon: ["fal", "newspaper"],
        },
      ]
    : []),
];
