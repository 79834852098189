import React from "react";
import { Heading, Button, Divider } from "@theme-ui/components";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

export default function BlockHeaderContainer({
  title,
  link,
  actionType,
  buttonText,
  action,
  rightComponent,
  classes,
  externalLink,
  options,
  setYear,
}) {
  return (
    <Header actionType={rightComponent ? true : actionType} className={classes}>
      <Heading>{title}</Heading>
      {rightComponent && rightComponent}
      {actionType === "link" &&
        (externalLink ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <Button>{buttonText}</Button>
          </a>
        ) : (
          <NavLink to={link}>
            <Button>{buttonText}</Button>
          </NavLink>
        ))}
      {actionType === "button" && (
        <Button onClick={() => action()}>{buttonText}</Button>
      )}
      {/* {actionType === "dropdown" && (
        <StyledForm>
          <Form
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            loading={loading}
            error={error}
            submitText={submitText}
            formFields={formFields}
            create={create}
            type="month"
            cancel={cancel}
            register={register}
          />
        </StyledForm>
      )} */}
      <FullDivider />
    </Header>
  );
}

const Header = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.actionType ? "1fr 1fr" : "1fr")};
  align-items: center;
  margin-bottom: 1.5em;
  text-transform: capitalize;
  row-gap: 1em;
  div,
  button,
  a {
    text-align: right;
    justify-self: end;
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    div,
    button,
    a {
      text-align: left;
      justify-self: start;
    }
  }
`;

const FullDivider = styled(Divider)`
  grid-column: 1 / span 2;

  @media (max-width: 576px) {
    grid-column: 1 / span 1;
  }
`;

// const customStyles = {
//   control: (provided, state) => ({
//     display: "flex",
//     alignItems: "center",
//     boxShadow: "inset 0 0.0625em 0.125em rgba(10,10,10,.05)",
//     backgroundColor: "#fff",
//     borderColor: state.isFocused
//       ? "var(--theme-ui-colors-tusk-shade-900,#CDCDD6) !important"
//       : "var(--theme-ui-colors-tusk-shade-500,#E2E2E7) !important",
//     border: "1px solid",
//     borderRadius: 4,
//     color: "#363636",
//     font: "inherit",
//     outline: 0,
//   }),
//   input: (provided, state) => ({
//     ...provided,
//     paddingBottom: "calc(.5em - 2px)",
//     paddingLeft: "calc(.75em - 2px)",
//     paddingRight: "calc(.75em - 2px)",
//     paddingTop: "calc(.5em - 2px)",
//   }),
//   container: (provided, state) => ({
//     ...provided,
//   }),

//   singleValue: (provided, state) => {
//     const opacity = state.isDisabled ? 0.5 : 1;
//     const transition = "opacity 300ms";

//     return { ...provided, opacity, transition };
//   },
// };
