import styled from "@emotion/styled";
import "./screens.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@theme-ui/components";
import React, { useEffect, useMemo, useState } from "react";
import { useFirebase } from "../../app/context/Firebase";
import { transformDocSnap } from "../../app/context/helpers";
import CardModal from "../../app/DSL/components/elements/CardModal";
import { RoundButton } from "../../app/DSL/components/elements/styled";
import Table from "../../app/DSL/components/elements/Table";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import EditModal from "./EditModal";
import { primary } from "../../app/DSL/theme";

export default function CasesTable({
  createMonth,
  editMonth,
  warehouseValues,
  goBack,
}) {
  const [cases, setCases] = useState(warehouseValues.saQuantities);
  const [curArea, setCurArea] = useState("saQuantities");
  const [editModal, setEditModal] = useState(false);
  const [rowIdx, setRowIdx] = useState(null);
  const firebase = useFirebase();
  const [tableValues, setTableValues] = useState(null);

  useEffect(() => {
    firebase
      .screen("warehouse")
      .onSnapshot((snap) => setTableValues(transformDocSnap(snap)));
  }, [editModal, firebase]);

  const closeEdit = () => {
    setEditModal(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Month",
        id: "name",
        disableFilters: true,
        accessor: "name",
        canFilter: false,
        isVisible: true,
        minWidth: 50,
        width: 100,
        maxWidth: 150,
      },
      {
        Header: "Cases",
        columns: [
          {
            Header: "Recon",
            id: "recon",
            disableFilters: true,
            accessor: "recon",
          },
          {
            Header: "Trauma",
            id: "trauma",
            disableFilters: true,
            accessor: "trauma",
          },
          {
            Header: "Spine",
            id: "spine",
            disableFilters: true,
            accessor: "spine",
          },
          {
            Header: "Sportsmed",
            id: "sportsmed",
            disableFilters: true,
            accessor: "sportsmed",
            minWidth: 50,
            width: 100,
            maxWidth: 150,
          },
          {
            Header: "Extremity",
            id: "extremity",
            disableFilters: true,
            accessor: "extremity",
          },
          {
            Header: "Total",
            id: "totalCases",
            disableFilters: true,
            accessor: "totalCases",
          },
        ],
        isVisible: true,
      },
      {
        Header: "Reservations",
        columns: [
          {
            Header: "Instruments",
            id: "instruments",
            disableFilters: true,
            accessor: "instruments",
          },
          {
            Header: "Implants",
            id: "implants",
            disableFilters: true,
            accessor: "implants",
          },
          {
            Header: "Total",
            id: "totalReservations",
            disableFilters: true,
            accessor: "totalReservations",
            maxWidth: 150,
          },
        ],
        isVisible: true,
      },
      {
        Header: "Actions",
        id: "actions",
        accessor: (u) => u.name,
        Cell: (cell) => {
          return (
            <span>
              <RoundButton
                key="edit"
                data-tip="View / Edit user"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setRowIdx(cell.row.index);
                  setEditModal(true);
                }}
              >
                <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
              </RoundButton>
            </span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
        isVisible: false,
        minWidth: 50,
        width: 100,
        maxWidth: 150,
      },
    ],
    []
  );

  return (
    <div className="cases-table">
      {cases === null || cases === [] || cases ? (
        <>
          <table className="button-table">
            <tbody>
              <tr>
                <td>
                  <button
                    className="filter-btn"
                    onClick={() => {
                      setCurArea("saQuantities");
                      setCases(warehouseValues.saQuantities);
                    }}
                    style={
                      curArea === "saQuantities"
                        ? { backgroundColor: "#58277d", color: "white" }
                        : null
                    }
                  >
                    SA
                  </button>
                </td>
                <td>
                  <button
                    className="filter-btn"
                    onClick={() => {
                      setCurArea("jhbQuantities");
                      setCases(warehouseValues.jhbQuantities);
                    }}
                    style={
                      curArea === "jhbQuantities"
                        ? { backgroundColor: "#58277d", color: "white" }
                        : null
                    }
                  >
                    JHB
                  </button>
                </td>
                <td>
                  <button
                    className="filter-btn"
                    onClick={() => {
                      setCurArea("bloemQuantities");
                      setCases(warehouseValues.bloemQuantities);
                    }}
                    style={
                      curArea === "bloemQuantities"
                        ? { backgroundColor: "#58277d", color: "white" }
                        : null
                    }
                  >
                    BLOEM
                  </button>
                </td>
                <td>
                  <button
                    className="filter-btn"
                    onClick={() => {
                      setCurArea("durbQuantities");
                      setCases(warehouseValues.durbQuantities);
                    }}
                    style={
                      curArea === "durbQuantities"
                        ? { backgroundColor: "#58277d", color: "white" }
                        : null
                    }
                  >
                    DURB
                  </button>
                </td>
                <td>
                  <button
                    className="filter-btn"
                    onClick={() => {
                      setCurArea("cptQuantities");
                      setCases(warehouseValues.cptQuantities);
                    }}
                    style={
                      curArea === "cptQuantities"
                        ? { backgroundColor: "#58277d", color: "white" }
                        : null
                    }
                  >
                    CPT
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          {cases === null || cases === [] ? (
            <>
              <p>No entries yet. Click below to add:</p>
            </>
          ) : (
            <>
              <CenterTable>
                <Styles>
                  <Table
                    emptyMsg="No cases"
                    columns={
                      curArea === "saQuantities"
                        ? columns.filter((col) => col.isVisible)
                        : columns
                    }
                    data={cases}
                    defaultRows={12}
                    emptyIcon="users"
                    pagination={false}
                    hideFilters={true}
                    loading={cases === undefined}
                  />
                </Styles>
              </CenterTable>
              {/* <div style={{ float: "right" }}>
                <p>Preview coming soon.</p>
              </div> */}
            </>
          )}

          {curArea !== "saQuantities" ? (
            <ButtonBox>
              <Button
                className="filter-button"
                onClick={() => {
                  createMonth();
                }}
                border={0}
                bg={"primary"}
                color={"white"}
              >
                Add Month
              </Button>
            </ButtonBox>
          ) : null}
        </>
      ) : null}
      {editModal === true ? (
        <CardModal isOpen={editModal === true} close={goBack}>
          <BlockHeaderContainer title={`Edit month`} />
          {editModal === true ? (
            <EditModal
              goBack={closeEdit}
              curArea={curArea}
              rowIdx={rowIdx}
              warehouseValues={warehouseValues}
            ></EditModal>
          ) : null}
        </CardModal>
      ) : null}
    </div>
  );
}

const ButtonBox = styled.div`
  width: 33%;
  height: 50px;
  margin-left: 5%;
`;

// const FilterBtn = styled.button`
//   width: 75px;
//   height: 50px;
//   color: ${props => props.color ? "white" : "#13133a"}
//   background-color: ${props => props.bg ? "#13133a" : "white"}
// `

const CenterTable = styled.div`
  margin-left: 5%;
  margin-bottom: 1%;
  padding-right: 5%;
`;
const Styles = styled.div`
  table {
    border-spacing: 0;

    th {
      border: 1px solid #e8e8e8;
      padding: 0.5rem;
    }

    td {
      padding: 0.5rem;
      margin: 0;
      height: 60px;
      border: 1px solid #e8e8e8;
    }
  }
`;
