import React, { useState } from "react";
import { useScreens } from "../../app/context/screens";
import EditForm from "./EditForm";
import { useToasts } from "react-toast-notifications";

export default function EditModal({
  goBack,
  curArea,
  rowIdx,
  warehouseValues,
}) {
  const { editMonth, setTotals } = useScreens();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const oldAreaContent = warehouseValues[curArea];
  const [newAreaContent, setNewAreaContent] = useState(null);
  const { addToast } = useToasts();

  //TODO: extract function
  function totalUp(formValues) {
    let totals = warehouseValues.saQuantities;
    var month = warehouseValues.saQuantities.find((m) => {
      return m.name === formValues.month + " " + formValues.year;
    });

    if (!month) {
      let newMonth = {
        name: formValues.month + " " + formValues.year,
        month: formValues.month,
        year: formValues.year,
        recon: parseInt(formValues.recon),
        trauma: parseInt(formValues.trauma),
        spine: parseInt(formValues.spine),
        sportsmed: parseInt(formValues.sportsmed),
        extremity: parseInt(formValues.extremity),
        totalCases:
          parseInt(formValues.recon) +
          parseInt(formValues.trauma) +
          parseInt(formValues.spine) +
          parseInt(formValues.sportsmed) +
          parseInt(formValues.extremity),
        instruments: parseInt(formValues.instruments),
        implants: parseInt(formValues.implants),
        totalReservations:
          parseInt(formValues.instruments) + parseInt(formValues.implants),
      };
      totals.push(newMonth);
      setTotals(totals);
    } else {
      let totalUpdate = {
        name: formValues.month + " " + formValues.year,
        month: formValues.month,
        year: formValues.year,
        recon: parseInt(formValues.recon) + month.recon,
        trauma: parseInt(formValues.trauma) + month.trauma,
        spine: parseInt(formValues.spine) + month.spine,
        sportsmed: parseInt(formValues.sportsmed) + month.sportsmed,
        extremity: parseInt(formValues.extremity) + month.extremity,
        totalCases:
          parseInt(formValues.recon) +
          parseInt(formValues.trauma) +
          parseInt(formValues.spine) +
          parseInt(formValues.sportsmed) +
          parseInt(formValues.extremity) +
          month.totalCases,
        instruments: parseInt(formValues.instruments) + month.instruments,
        implants: parseInt(formValues.implants) + month.implants,
        totalReservations:
          parseInt(formValues.instruments) +
          parseInt(formValues.implants) +
          month.totalReservations,
      };
      const index = totals.findIndex(
        (i) => i.name === formValues.month + " " + formValues.year
      );
      totals[index] = totalUpdate;
      setTotals(totals);
    }
  }

  const handleSubmit = async (e, formValues, reset, createAnother) => {
    setLoading(true);
    e.preventDefault();

    const newMonth = {
      name: formValues.month + " " + formValues.year,
      month: formValues.month,
      year: formValues.year,
      recon: parseInt(formValues.recon),
      trauma: parseInt(formValues.trauma),
      spine: parseInt(formValues.spine),
      sportsmed: parseInt(formValues.sportsmed),
      extremity: parseInt(formValues.extremity),
      totalCases:
        parseInt(formValues.recon) +
        parseInt(formValues.trauma) +
        parseInt(formValues.spine) +
        parseInt(formValues.sportsmed) +
        parseInt(formValues.extremity),
      instruments: parseInt(formValues.instruments),
      implants: parseInt(formValues.implants),
      totalReservations:
        parseInt(formValues.instruments) + parseInt(formValues.implants),
    };

    console.log(oldAreaContent);

    const oldArea = oldAreaContent;
    oldArea[rowIdx] = newMonth;

    console.log(oldArea);
    setNewAreaContent(oldArea);

    console.log(newAreaContent);

    return editMonth(oldArea, curArea)
      .then(() => {
        addToast("Month edited successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
          transitionDuration: 10,
          transitionState: "entered",
        });
        totalUp(formValues);
        setLoading(false);
        goBack();
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);

        setError(error);
        console.log(error);
      });
  };
  return (
    <EditForm
      loading={loading}
      error={error}
      handleSubmit={handleSubmit}
      submitText="Edit Month"
      create={false}
      cancel={goBack}
      initialValues={{ role: undefined }}
    />
  );
}
