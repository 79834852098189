import { Card } from "@theme-ui/components";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../app/context/auth";
import { useFirebase } from "../../app/context/Firebase";
import { transformDocSnap } from "../../app/context/helpers";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import SalesBudgetTable from "../Dashboard/SalesBudgetTable";

const Sales = () => {
  const [values, setValues] = useState(null);
  const { user } = useAuthContext();
  const firebase = useFirebase();

  const setSalesValues = (salesValues) => {
    setValues(salesValues);
  };

  useEffect(() => {
    const unsubscribe = user
      ? firebase
          .screen("sales")
          .onSnapshot((snap) => setValues(transformDocSnap(snap)))
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  return (
    <div>
      <PageHeaderContainer>Sales Screen</PageHeaderContainer>

      <Card>
        <BlockHeaderContainer
          title="Sales Budget"
          actionType="link"
          link="https://screens.zimmer-biomet.co.za/sales"
          buttonText="View live screen"
          externalLink
        />
        {values ? (
          <SalesBudgetTable setSalesValues={setSalesValues} values={values} />
        ) : (
          "Loading"
        )}
      </Card>
    </div>
  );
};

export default Sales;
