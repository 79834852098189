import React from "react";
import styled from "@emotion/styled";
import { Card, Button, Image } from "@theme-ui/components";
import { useAuthContext } from "../../app/context/auth";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { primary } from "../../app/DSL/theme";

const NotApproved = () => {
  const { claims } = useAuthContext();
  return (
    <div>
      <PageHeaderContainer image>
        <Image
          src={`/assets/${
            process.env.REACT_APP_LOGO
              ? process.env.REACT_APP_LOGO
              : "long-logo.svg"
          }`}
          width="520px"
        />
      </PageHeaderContainer>
      {!claims.isApproved && (
        <MessageCard>
          <h3>Your account has not yet been approved</h3>
          <p>
            An admin will review your account as soon as possible.
            <br />
            You will recieve an email once your account has been approved. If
            you have recieved the approval email and are seeing this message
            please try logging out and back in again.
          </p>
        </MessageCard>
      )}
      {claims.firstLogin && (
        <MessageCard>
          <h3>You have not activated your account yet</h3>
          <p>Please first log in to HQ to activate your account</p>
          <p>
            If you have logged in to HQ before and are seeing this message
            please try logging out and back in again.
          </p>
          <a href={process.env.REACT_APP_HQ_LINK}>
            <Button variant="outlineWhite"> Go to HQ</Button>
          </a>
        </MessageCard>
      )}
    </div>
  );
};

export default NotApproved;

const MessageCard = styled(Card)`
  padding: 1em 2em;
  margin-bottom: 1em;
  background: ${(props) => props.theme.colors[primary].shade[700]};
  color: ${(props) => props.theme.colors[primary].font[700]};
`;
