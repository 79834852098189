import { Button, Input, Label } from "@theme-ui/components";
import React, { useEffect, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useAuthContext } from "../../app/context/auth";
import { useFirebase } from "../../app/context/Firebase";
import { transformDocSnap } from "../../app/context/helpers";
import { useScreens } from "../../app/context/screens";

export default function OpenOrders() {
  const { updateOpenDirectOrders, updateOpenOrders } = useScreens();
  const { addToast } = useToasts();
  const { user } = useAuthContext();
  const firebase = useFirebase();
  const [openOrders, setOpenOrders] = useState();
  const [curTab, setCurTab] = useState("pickSlips");
  const [consignment, setConsignment] = useState(0);
  const [directOrders, setDirectOrders] = useState(0);
  const [branches, setBranches] = useState(0);

  useEffect(() => {
    const unsubscribe = user
      ? firebase.screen("openOrders").onSnapshot((snap) => {
          console.log(snap);
          setOpenOrders(transformDocSnap(snap));
        })
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  useEffect(() => {
    if (openOrders) {
      setConsignment(openOrders[curTab].consignment);
      setDirectOrders(openOrders[curTab].directOrders);
      setBranches(openOrders[curTab].branches);
    }
  }, [openOrders, curTab]);

  const values = useMemo(() => {
    return {
      consignment: consignment,
      directOrders: directOrders,
      branches: branches,
    };
  }, [consignment, directOrders, branches]);

  return (
    <>
      <table style={{ marginBottom: "1em" }}>
        <tbody>
          <tr>
            <td>
              <button
                className="filter-btn"
                onClick={() => {
                  setCurTab("pickSlips");
                }}
                style={
                  curTab === "pickSlips"
                    ? { backgroundColor: "#58277d", color: "white" }
                    : null
                }
              >
                Pick Slips
              </button>
            </td>
            <td>
              <button
                className="filter-btn"
                onClick={() => {
                  setCurTab("quantity");
                }}
                style={
                  curTab === "quantity"
                    ? { backgroundColor: "#58277d", color: "white" }
                    : null
                }
              >
                Quantity
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <Label style={{ marginTop: "0.5em" }}>Consignment</Label>
      <Input
        type="number"
        value={consignment}
        onChange={(e) => setConsignment(e.target.value)}
      />
      <Label style={{ marginTop: "0.5em" }}>Direct Orders</Label>
      <Input
        type="number"
        value={directOrders}
        onChange={(e) => setDirectOrders(e.target.value)}
      />
      <Label style={{ marginTop: "0.5em" }}>Branches</Label>
      <Input
        type="number"
        value={branches}
        onChange={(e) => setBranches(e.target.value)}
      />
      <Button
        style={{ marginTop: "1em" }}
        onClick={() => {
          updateOpenOrders(values, curTab).then(() => {
            addToast("Open direct orders updated", {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 1500,
            });
          });
        }}
      >
        Update
      </Button>
    </>
  );
}
