import { Button, Card, Input, Label } from "@theme-ui/components";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../app/context/auth";
import { useFirebase } from "../../app/context/Firebase";
import { transformDocSnap } from "../../app/context/helpers";
import { useScreens } from "../../app/context/screens";
import CardModal from "../../app/DSL/components/elements/CardModal";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import ScreenSettings from "../Dashboard/ScreenSettings";
import AddMonth from "./AddMonth";
import CasesTable from "./CasesTable";
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";
import { useMemo } from "react";
import OpenOrders from "../Dashboard/OpenOrders";

export default function Warehouse() {
  const [curYear, setCurYear] = useState("2021");
  const [view, setView] = useState("list");
  const [warehouseValues, setWarehouseValues] = useState(null);
  const [openDirectOrders, setOpenDirectOrders] = useState(null);
  const { user } = useAuthContext();
  const firebase = useFirebase();
  const { updateOpenDirectOrders } = useScreens();
  const { addToast } = useToasts();
  useEffect(() => {
    const unsubscribe = user
      ? firebase
          .screen("warehouse")
          .onSnapshot((snap) => setWarehouseValues(transformDocSnap(snap)))
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  useEffect(() => {
    const unsubscribe = user
      ? firebase
          .screen("openDirectOrders")
          .onSnapshot((snap) =>
            setOpenDirectOrders(transformDocSnap(snap).count)
          )
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  const goBack = () => {
    setView("list");
  };
  const setYear = (year) => {
    setCurYear(year);
  };
  const setCreate = () => {
    setView("create");
  };
  const setEdit = () => {
    setView("edit");
  };
  const yearOptions = [{ label: "2021", value: "2021" }];
  return (
    <div className="warehouse-screens">
      <PageHeaderContainer>Warehouse Screens</PageHeaderContainer>
      <Card>
        <BlockHeaderContainer title={`Open Direct Orders`} />
        <OpenOrdersInput>
          <Input
            type="number"
            value={openDirectOrders}
            onChange={(e) => setOpenDirectOrders(e.target.value)}
          />
          <Button
            onClick={() => {
              updateOpenDirectOrders(openDirectOrders).then(() => {
                addToast("Open direct orders updated", {
                  appearance: "success",
                  autoDismiss: true,
                  autoDismissTimeout: 1500,
                });
              });
            }}
          >
            Update
          </Button>
        </OpenOrdersInput>
      </Card>

      <Card style={{ marginTop: "3em" }}>
        <BlockHeaderContainer title={`Open Orders`} />
        <OpenOrders />
      </Card>

      <Card style={{ marginTop: "3em" }}>
        <BlockHeaderContainer
          title="Quantities"
          actionType="link"
          buttonText="View live screen"
          options={yearOptions}
          link="https://screens.zimmer-biomet.co.za/warehouse"
          setYear={setYear}
          externalLink
        />
        {warehouseValues !== null ? (
          <CasesTable
            createMonth={() => setCreate()}
            editMonth={() => setEdit()}
            warehouseValues={warehouseValues}
            goBack={goBack}
          />
        ) : null}
        <div className="settings">
          <BlockHeaderContainer title="Display Settings"></BlockHeaderContainer>
          <ScreenSettings />
        </div>
      </Card>
      <CardModal isOpen={view === "create" || view === "edit"} close={goBack}>
        <BlockHeaderContainer title={`${view} month`} />
        {view === "create" ? (
          <AddMonth
            goBack={goBack}
            warehouseValues={warehouseValues}
          ></AddMonth>
        ) : null}
      </CardModal>
    </div>
  );
}

const OpenOrdersInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
