import React, { createContext, useState, useContext, useEffect } from "react";
import { MenuProvider } from "../menu";
import { PermissionsProvider } from "../permissions";
import { ScreensProvider } from "../screens";

export const DataProvider = ({ children }) => {
  return (
    <MenuProvider>
      <PermissionsProvider>
        <ScreensProvider>{children}</ScreensProvider>
      </PermissionsProvider>
    </MenuProvider>
  );
};

const LoadingContext = createContext({});

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadingArr = [];
    console.log(loadingArr);
    setLoading(
      !loadingArr.every((l) => {
        return l === false;
      })
    );
  }, []);

  return (
    <LoadingContext.Provider value={{ loading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const LoadingConsumer = LoadingContext.Consumer;

export const useLoading = () => useContext(LoadingContext);
