import styled from "@emotion/styled";
import React from "react";
import Form from "../../app/forms/Form";

export default function EditForm({
  initialValues,
  handleSubmit,
  loading,
  error,
  submitText,
  create,
  profile,
  register,
  cancel,
}) {
  const months = [
    { label: "January", value: "Jan" },
    { label: "February", value: "Feb" },
    { label: "March", value: "Mar" },
    { label: "April", value: "Apr" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "Aug" },
    { label: "September", value: "Sep" },
    { label: "October", value: "Oct" },
    { label: "November", value: "Nov" },
    { label: "December", value: "Dec" },
  ];
  const years = [
    { label: "2021", value: "2021" },
    { label: "2020", value: "2020" },
    { label: "2019", value: "2019" },
    { label: "2018", value: "2018" },
    { label: "2017", value: "2017" },
    { label: "2016", value: "2016" },
    { label: "2015", value: "2015" },
  ];
  const formFields = [
    {
      name: "month",
      title: "Month",
      type: "select",
      options: months,
      validate: (x) => x && x.length > 0,
      error: "Please select a month",
      placeholder: "--- Select a month --",
      selectedOption: initialValues.area
        ? months.find((d) => initialValues.area === d.value)
        : {},
    },
    {
      name: "year",
      title: "Year",
      type: "select",
      options: years,
      validate: (x) => x && x.length > 0,
      error: "Please select a month",
      placeholder: "--- Select a month --",
      selectedOption: initialValues.area
        ? years.find((d) => initialValues.area === d.value)
        : {},
    },
    {
      name: "recon",
      title: "Recon",
      type: "text",
      placeholder: "0",
    },
    {
      name: "trauma",
      title: "Trauma",
      type: "text",
      placeholder: "0",
    },
    {
      name: "spine",
      title: "Spine",
      type: "text",
      placeholder: "0",
    },
    {
      name: "sportsmed",
      title: "Sportsmed",
      type: "text",
      placeholder: "0",
    },
    {
      name: "extremity",
      title: "Extremity",
      type: "text",
      placeholder: "0",
    },
    {
      name: "instruments",
      title: "Instruments",
      type: "text",
      placeholder: "0",
    },
    {
      name: "implants",
      title: "Implants",
      type: "text",
      placeholder: "0",
    },
  ];
  return (
    <StyledForm>
      <Form
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        loading={loading}
        error={error}
        submitText={submitText}
        formFields={formFields}
        create={create}
        type="month"
        cancel={cancel}
        register={register}
      />
    </StyledForm>
  );
}

const StyledForm = styled.div`
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    grid-template-rows: auto;
    grid-template-areas: ${(props) => {
      switch (props.context) {
        case "month":
          return `"month month" "year year" "recon recon" "trauma trauma" "spine spine" "sportsmed sportsmed" "extremity extremity" "instruments instruments"  "implants implants" "error error" "button button"`;
        default:
          return `"month month" "year year" "recon recon" "trauma trauma" "spine spine" "sportsmed sportsmed" "extremity extremity" "instruments instruments"  "implants implants" "error error" "button button"`;
      }
    }};
  }
`;
