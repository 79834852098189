import React, { createContext, useContext } from "react";
import { useFirebase } from "../Firebase";

const ScreensContext = createContext({});

//TODO: Move assigning screens here

export const ScreensProvider = ({ children }) => {
  const firebase = useFirebase();

  const updateScreen = (screen, content) => {
    return firebase.screen(screen).set(content, { merge: true });
  };

  const addMonth = (content, area) => {
    return firebase.screen("warehouse").set(
      {
        [area]: firebase.firestore.FieldValue.arrayUnion(content),
      },
      { merge: true }
    );
  };

  const updateOpenDirectOrders = (count) => {
    return firebase.screen("openDirectOrders").set({ count: count });
  };

  const updateOpenOrders = (values, section) => {
    return firebase.screen("openOrders").update({ [section]: values });
  };

  const editMonth = (content, area) => {
    let areaArray = [];
    content.map((e) => {
      areaArray.push({
        name: e.name,
        recon: e.recon,
        trauma: e.trauma,
        spine: e.spine,
        sportsmed: e.sportsmed,
        extremity: e.extremity,
        totalCases: e.totalCases,
        instruments: e.instruments,
        implants: e.implants,
        totalReservations: e.totalReservations,
      });
    });
    return firebase.screen("warehouse").update({
      [area]: content,
    });
  };

  const setTotals = (newTotals) => {
    return firebase.screen("warehouse").update({
      saQuantities: newTotals,
    });
  };

  const updateSettings = (view, compare1, compare2) => {
    return firebase.screen("settings").update({
      view: view,
      compare1: compare1,
      compare2: compare2,
    });
  };

  return (
    <ScreensContext.Provider
      value={{
        updateScreen,
        addMonth,
        editMonth,
        setTotals,
        updateSettings,
        updateOpenDirectOrders,
        updateOpenOrders,
      }}
    >
      {children}
    </ScreensContext.Provider>
  );
};

export const ScreensConsumer = ScreensContext.Consumer;

export const useScreens = () => useContext(ScreensContext);
