import styled from "@emotion/styled";
import React from "react";
import "react-circular-progressbar/dist/styles.css";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

export default function SalesPreview({ values }) {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    <Wrapper>
      {values ? (
        <div>
          <div>
            <Header>
              <img src="/assets/screens/logo.svg" alt="" />

              <h1>{values?.date}</h1>
              <h2>{values?.title}</h2>
            </Header>
            <Banner>
              <div>
                <h3>R{currencyFormatter.format(parseInt(values?.budget))}</h3>
              </div>
              <img
                src="/assets/screens/main-banner.svg"
                className="large-banner"
                alt=""
              />
              <img
                src="/assets/screens/small-banner.svg"
                className="small-banner"
                alt=""
              />
            </Banner>
          </div>
          <ProgressContainer>
            <Progress>
              <CircularProgressbarWithChildren
                strokeWidth={values?.mtd <= 100 ? (values?.mtd / 100) * 5 : 5}
                value={values?.mtd <= 100 ? values?.mtd : 100}
                /* This is important to include, because if you're fully managing the
                      animation yourself, you'll want to disable the CSS animation. */
                styles={{
                  ...buildStyles({
                    pathTransition: "none",
                    trailColor: "#ffffff20",
                    textColor: "white",
                    pathColor: "white",
                  }),
                }}
              ></CircularProgressbarWithChildren>
              <CircularProgressbarWithChildren
                strokeWidth={values?.mtd <= 100 ? (values?.mtd / 100) * 5 : 5}
                value={values?.mtd > 100 ? values?.mtd - 100 : 0}
                /* This is important to include, because if you're fully managing the
                      animation yourself, you'll want to disable the CSS animation. */
                styles={{
                  ...buildStyles({
                    pathTransition: "none",
                    trailColor: "transparent",
                    textColor: "white",
                    pathColor: "#fdc306",
                  }),
                }}
              >
                <div
                  style={{
                    zIndex: 1,
                    textAlign: "center",
                    background: "#ffffff20",
                    borderRadius: "50%",
                    height: `${
                      values?.mtd >= 100
                        ? 180
                        : values?.mtd > 30
                        ? 180 * (values?.mtd / 100)
                        : 60
                    }px`,
                    width: `${
                      values?.mtd >= 100
                        ? 180
                        : values?.mtd > 30
                        ? 180 * (values?.mtd / 100)
                        : 60
                    }px`,
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        marginBottom: "-0.3em",
                        fontSize: `${
                          values?.mtd >= 100
                            ? 3
                            : values?.mtd > 30
                            ? 3 * (values?.mtd / 100)
                            : 1
                        }rem`,
                      }}
                    >
                      {values?.mtd}%
                    </h3>
                    <h4
                      style={{
                        marginBottom: "-0.3em",
                        fontSize: `${
                          values?.mtd >= 100
                            ? 3
                            : values?.mtd > 30
                            ? 3 * (values?.mtd / 100)
                            : 1
                        }rem`,
                      }}
                    >
                      MTD
                    </h4>
                  </div>
                </div>
              </CircularProgressbarWithChildren>
            </Progress>
          </ProgressContainer>
        </div>
      ) : null}
      <Bottom>
        <h3>{values?.bottom_message}</h3>
      </Bottom>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  background: #0078af;
  position: relative;
  display: grid;
  align-items: center;

  * {
    color: white;
    text-transform: uppercase;
    margin: 0;
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
`;

const Header = styled.div`
  margin-left: 1rem !important;
  img {
    width: 100px;
  }
`;

const Banner = styled.div`
  width: 70%;
  position: relative;
  z-index: 10;
  div {
    h3 {
      position: relative;
      z-index: 30;
      margin: 0;
    }
    display: grid;
    align-items: center;
    padding-left: 1rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .small-banner {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
const ProgressContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: grid;
  align-items: center;
`;
const Progress = styled.div`
  justify-self: flex-end;
  margin-right: 1rem !important;
  height: 180px;
  width: 180px;
  z-index: 1;
  transform: translateY(-20px);
  > div {
    display: grid;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  svg {
    position: relative;
    z-index: 10;
  }
`;

const Bottom = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  background: white;
  align-self: end;
  display: grid;
  align-items: center;
  text-align: center;
  h3 {
    text-transform: capitalize !important;
    color: #0078af;
  }
`;
