import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../features/auth/Login";
import { useAuthContext } from "./context/auth";
import Error404 from "./Error404";
import ForgotPassword from "../features/auth/ForgotPassword";
import { useEffect } from "react";
import { useMenu } from "./context/menu";
import Sales from "../features/Sales";
import NotApproved from "../features/NotApproved";
import Warehouse from "../features/Warehouse";
import NoAccess from "../features/auth/NoAccess";

const Wrapper = ({ location, children }) => {
  const { setShowAdminBar } = useMenu();

  useEffect(() => {
    setShowAdminBar(location?.pathname?.includes("admin-area"));
  }, [location, setShowAdminBar]);
  return children;
};

export default function Router() {
  const auth = useAuthContext();
  //TODO: extract routes to separate files
  const authRoutes = [
    { path: "/sales", component: (props) => <Sales {...props} /> },
    { path: "/warehouse", component: (props) => <Warehouse {...props} /> },
  ];

  const notApprovedRoutes = [
    {
      path: "/dashboard",
      component: (props) => <NotApproved {...props} />,
    },
  ];

  const createRoutes = (routes) => {
    return routes.map((route, idx) => (
      <Route
        exact
        path={route.path}
        render={(props) => {
          return <Wrapper {...props}>{route.component(props)}</Wrapper>;
        }}
        key={idx}
      />
    ));
  };

  return (
    <Switch>
      <Route exact path="/" render={() => <Login />} />
      <Route exact path="/forgot-password" render={() => <ForgotPassword />} />
      {auth?.claims?.isApproved && createRoutes(authRoutes)}

      {!auth?.claims?.isApproved && createRoutes(notApprovedRoutes)}
      {auth?.user && (
        <Route exact path="/no-access" render={() => <NoAccess />} />
      )}
      {auth?.user && (
        <Route
          render={(props) => (
            <Wrapper {...props}>
              <Error404 />
            </Wrapper>
          )}
        />
      )}
    </Switch>
  );
}
