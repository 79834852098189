import { Button, Checkbox, Label, Input } from "@theme-ui/components";
import React from "react";
import {
  IconLeft,
  IconRight,
  InputWrap,
} from "../../app/DSL/components/form/StyledInput";
import SalesPreview from "./SalesPreview";
import styled from "@emotion/styled";
import { useScreens } from "../../app/context/screens";
import { useToasts } from "react-toast-notifications/dist/ToastProvider";

export default function SalesBudgetTable({ setSalesValues, values }) {
  const { updateScreen } = useScreens();
  const { addToast } = useToasts();
  const update = () => {
    updateScreen("sales", values).then(() => {
      addToast("Sales screen updated", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
    });
  };
  return (
    <Grid>
      <div>
        <InputWrap>
          <Label>Month and Year</Label>
          <Input
            value={values.date}
            onChange={(e) => {
              e.persist();
              setSalesValues((prev) => ({
                ...prev,
                date: e.target.value,
              }));
            }}
          ></Input>
        </InputWrap>
        <InputWrap>
          <Label>Title</Label>
          <Input
            value={values.title}
            onChange={(e) => {
              e.persist();
              setSalesValues((prev) => ({
                ...prev,
                title: e.target.value,
              }));
            }}
          ></Input>
        </InputWrap>
        <Grid style={{ alignItems: "end" }}>
          <InputWrap icon="left">
            <Label>Budget amount</Label>
            <Input
              value={values.budget}
              onChange={(e) => {
                e.persist();
                setSalesValues((prev) => ({
                  ...prev,
                  budget: e.target.value,
                }));
              }}
            ></Input>
            <IconLeft>
              {" "}
              <p style={{ margin: 0 }}>R</p>
            </IconLeft>
          </InputWrap>
          <InputWrap style={{ marginBottom: "1em" }}>
            <Label variant="radioLabel" style={{ textTransform: "none" }}>
              <Checkbox
                checked={values.showBudget}
                onChange={(e) => {
                  e.persist();
                  setSalesValues((prev) => ({
                    ...prev,
                    showBudget: !values.showBudget,
                  }));
                }}
              />
              Show budget?
            </Label>
          </InputWrap>
        </Grid>
        <InputWrap icon="right">
          <Label>MTD %</Label>
          <Input
            value={values.mtd}
            onChange={(e) => {
              e.persist();
              setSalesValues((prev) => ({
                ...prev,
                mtd: e.target.value,
              }));
            }}
          ></Input>
          <IconRight>
            <p style={{ margin: 0 }}>%</p>
          </IconRight>
        </InputWrap>
        <InputWrap>
          <Label>Bottom Message</Label>
          <Input
            value={values.bottom_message}
            onChange={(e) => {
              e.persist();
              setSalesValues((prev) => ({
                ...prev,
                bottom_message: e.target.value,
              }));
            }}
          ></Input>
        </InputWrap>
        <Button onClick={update}>Update</Button>
      </div>
      <div>
        <Preview>
          <SalesPreview values={values} />
        </Preview>
      </div>
    </Grid>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
  row-gap: 2em;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Preview = styled.div`
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  height: 300px;
  width: 500px;
  border-radius: 10px;
  overflow: hidden;
`;
